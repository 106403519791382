// vue
import { ref, type Ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type {
  Nullable,
  OrbitApiMeta,
  OrbitApiRequestPayload,
  TutorPackageTranscript
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useTutorPackageTranscriptStore =
  defineStore('tutor-package-transcript', () => {
    // fetch repositories
    const { $tutorPackageTranscripts } = useNuxtApp()

    // state
    const customTutorPackageTranscript =
      ref<Nullable<TutorPackageTranscript>>(null) as Ref<Nullable<TutorPackageTranscript>>
    const requestMeta = ref<Nullable<OrbitApiMeta>>(null)
    const requestPayload = ref<Nullable<OrbitApiRequestPayload>>({})
    const tutorPackageTranscript =
      ref<Nullable<TutorPackageTranscript>>(null) as Ref<Nullable<TutorPackageTranscript>>
    const tutorPackageTranscripts =
      ref<TutorPackageTranscript[]>([]) as Ref<TutorPackageTranscript[]>

    // actions
    async function index (config?: NitroFetchOptions<string>) {
      const { tutorPackageTranscripts: _tutorPackageTranscripts, meta } =
        await $tutorPackageTranscripts
          .index<{
            tutorPackageTranscript: TutorPackageTranscript[],
            meta: OrbitApiMeta
          }>(config)
      tutorPackageTranscripts.value = _tutorPackageTranscripts || []

      if (meta) {
        requestMeta.value = meta
      }
      return tutorPackageTranscripts
    }

    async function show (
      id: number,
      config?: NitroFetchOptions<string>
    ) {
      const { tutorPackageTranscript: _tutorPackageTranscript } =
        await $tutorPackageTranscripts
          .show<{ tutorPackageTranscript: TutorPackageTranscript }>(id, config)
      tutorPackageTranscript.value = _tutorPackageTranscript
      return tutorPackageTranscript
    }

    async function create (
      payload?: Record<string, unknown>,
      config?: NitroFetchOptions<string>,
      stateProperty: 'custom' | 'default' = 'default'
    ) {
      const { tutorPackageTranscript: _tutorPackageTranscript } =
        await $tutorPackageTranscripts
          .create<{
            tutorPackageTranscript: TutorPackageTranscript
          }>(payload, config)

      if (stateProperty === 'custom') {
        customTutorPackageTranscript.value = _tutorPackageTranscript
      } else {
        tutorPackageTranscript.value = _tutorPackageTranscript
      }

      if (_tutorPackageTranscript) {
        tutorPackageTranscripts.value =
          [...tutorPackageTranscripts.value, _tutorPackageTranscript]
      }
      return tutorPackageTranscript
    }

    async function update (
      id: number,
      payload?: Record<string, unknown>,
      config?: NitroFetchOptions<string>
    ) {
      const { tutorPackageTranscript: _tutorPackageTranscript } =
        await $tutorPackageTranscripts
          .update<{ tutorPackageTranscript: TutorPackageTranscript}>(
            id,
            payload,
            config
          )

      tutorPackageTranscript.value = _tutorPackageTranscript

      if (
        tutorPackageTranscripts.value.length &&
        tutorPackageTranscript.value
      ) {
        tutorPackageTranscripts.value =
          tutorPackageTranscripts.value.filter((tutorPackageTranscript) => {
            return tutorPackageTranscript.id !== id
          })
        tutorPackageTranscripts.value =
          [...tutorPackageTranscripts.value, tutorPackageTranscript.value]
      }

      return tutorPackageTranscript
    }

    async function deleteTutorPackageTranscript (
      id: number,
      payload?: Record<string, unknown>,
      config?: NitroFetchOptions<string>
    ) {
      await $tutorPackageTranscripts.delete(
        id,
        payload,
        config
      )

      if (tutorPackageTranscripts.value.length) {
        tutorPackageTranscripts.value =
          tutorPackageTranscripts.value.filter((tutorPackageTranscript) => {
            return tutorPackageTranscript.id !== id
          })
      }
    }

    function deleteRequestPayloadItem (keyToDelete: string) {
      const newPayload = {} as OrbitApiRequestPayload
      for (const payloadItem in requestPayload.value) {
        if (payloadItem !== keyToDelete) {
          newPayload[payloadItem] = requestPayload.value[payloadItem]
        }
      }
      requestPayload.value = newPayload
    }

    function updateRequestPayload (payload: OrbitApiRequestPayload) {
      if (payload.resetPayloadObject) {
        requestPayload.value = null
        return
      }
      requestPayload.value = {
        ...requestPayload.value,
        ...payload
      }
    }

    return {
      customTutorPackageTranscript,
      tutorPackageTranscript,
      tutorPackageTranscripts,
      requestMeta,
      requestPayload,
      create,
      deleteRequestPayloadItem,
      deleteTutorPackageTranscript,
      index,
      show,
      update,
      updateRequestPayload
    }
  })
